import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import LogoIconNew from '../../images/luidsprekertje-icon.png';
import { Link } from "gatsby"

import Button from '../Button';


const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container mx-auto flex flex-wrap items-center justify-between max-w-7xl mx-auto md:pt-8 md:pb-8 md:pr-6 md:pl-6 text-xl">
      <div className="flex items-center text-2xl">
              <Link to="/">
          <h1 className="flex items-center no-underline">
            <span className="text-xl font-bold tracking-tight text-black font-playfair">
              Deceulener
            </span>
          </h1>
        </Link>
      </div>
      
      	<Link to="/index-fr">
            <button className="font-playfair text-black">Revenir à la page d'accueil.</button>
          </Link>
      
    </div>
  </header>
);

export default Header;
