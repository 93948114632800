import React from "react";
import Layout from "../components/Layout/LayoutFr2"
import { useForm } from "react-hook-form";
import { useState } from 'react';
import EnvelopeImg from "../images/envelopje.png"

export default function App() {
  const { register, handleSubmit, reset, setError, formState: { errors } } = useForm();
  const { formState: {isSubmitting}} = useForm();
  const [submitted, setSubmitted] = useState(false);
  const GATEWAY_URL = 'https://l3okojrroc.execute-api.eu-west-1.amazonaws.com/prod/submit';
  const onSubmit = data => {
  try {
    fetch(GATEWAY_URL, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    reset();
    alert('Bedankt voor uw boodschap. Uw email werd verzonden. Wij nemen spoedig contact met u op.')
    setSubmitted(true);
  } catch (error) {
    setError('submit', 'submitError', `Doh! ${error.message}`);
  }
};

  return (
	<Layout>
        <section className="pt-10 md:pt-20">
			<div className="container mx-auto px-8 lg:flex">
				<div className="text-center lg:text-left lg:w-1/2">
					<h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
						Contact
					</h1>
					<p className="text-xl mt-6">
						Contactez-nous pour plus d'informations ou pour des questions.
					</p>
					<p className="text-xl mt-6">
					N'hésitez pas à prendre rendez-vous ou à visiter notre showroom.					
					</p>
					
					<form onSubmit={handleSubmit(onSubmit)} class="w-full max-w-lg pl-4">
						<input id="from" type="hidden" {...register('from',{ required: true })} defaultValue="postmaster@deceulener-interieur.be" />
						<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
							<div className="md:w-1/3 min-w-full">
						      <label htmlFor="name" class="block text-xl mt-6 font-light">Nom</label>
						    </div>
						    <div className="md:w-2/3 min-w-full">  
						      <input
						      	className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						        id="name"
						        aria-invalid={errors.firstName ? "true" : "false"}
						        {...register('name', { required: true })}
						      />
						      {errors.name && (
						        <span role="alert">
						          Champ obligatoire
						        </span>
						      )}
							</div>
						</div>
						
						<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
							<div className="md:w-1/3 min-w-full">
						      <label htmlFor="email" class="text-xl mt-6 font-light">Email</label>
						     </div>
						    <div className="md:w-2/3 min-w-full">  
						      <input
						        id="email"
						        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						        aria-invalid={errors.email ? "true" : "false"}
						        {...register('email', { required: true })}
						      />
						      {errors.email && (
						        <span role="alert">
						          This field is required
						        </span>
						      )}
							</div>
						</div>
						
						<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
							<div className="md:w-1/3 min-w-full">
						      <label htmlFor="phone" className="text-xl mt-6 font-light">Téléphone</label>
						    </div>
						    <div className="md:w-2/3 min-w-full">  
						      <input
						        id="phone"
						        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						        aria-invalid={errors.phone ? "true" : "false"}
						        {...register('phone', { required: false, maxLength: 30  })}
						      />
						      {errors.phone && (
						        <span role="alert">
						          Champ obligatoire.
						        </span>
						      )}
							</div>
						</div>
						
						<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
							<div className="md:w-1/3 min-w-full">
						      <label htmlFor="desc" className="text-xl mt-6 font-light">Message</label>
						    </div>
						    <div className="md:w-2/3 min-w-full">  
						      <textarea
							  {...register('desc', { required: true, maxLength: 800  })}
							  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							  name="desc"
							  id="desc"
							  rows="3"
							  placeholder="Votre message"
							  />
						      {errors.desc && (
						        <span role="alert">
						          Champ obligatoire 
						        </span>
						      )}
							</div>
						</div>
						
						<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
							<div className="md:w-1/3 min-w-full">
							</div>
							<div className="md:w-2/3 min-w-full"> 
								<div>
							      <input 
								  disabled={isSubmitting} 
								  type="submit" 
								  className="block w-full mb-10 mt-8 border-2 rounded px-6 py-1 bg-black hover:bg-primary-darker text-white"
								  value="Envoyer"
								  />
								</div>
							</div>
						</div>
					</form>		
				</div>
				
				<div className="mr-12 lg:w-80">
					<p className="pl-8 mt-4 text-gray-600">
						Deceulener Intérieur
					</p>
					<p className="pl-8 mt-4 text-gray-600">
					Rue de la Basilique 143
					</p>
					<p className="pl-8 mt-4 text-gray-600">
						1500 Halle
					</p>
					<p className="pl-8 mt-4 text-gray-600">
						tél. 02/356.60.15
					</p>
					<p className="pl-8 mt-4 text-gray-600">
					<b>Heures d 'ouverture : </b>
					</p>
					<p className="pl-8 mt-4 text-gray-600">
					Lundi: fermé
					</p>
					<p className="pl-8 mt-4 text-gray-600">
					Mardi – Samedi de 9h jusqu'à 12h. et de 14h jusqu'à 18u
					</p>
					<p className="pl-8 mt-4 text-gray-600">
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1262.683827678541!2d4.2373409697725535!3d50.731669545647954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c85681e03601%3A0xc71818c54ac5cf46!2sDeceulener%20Interieur!5e0!3m2!1sen!2sbe!4v1651000794315!5m2!1sen!2sbe" width="600" height="450">
					</iframe>
					</p>
				</div>
				
				
					
		
				
			</div>
		</section>
	</Layout>
  );
}